@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';









































































































































































































































































































































































































































































































































































































































































































































































































































































































































.hl {
  background-color: #fff3e0;
  font-weight: bold;
  color: orangered;
}
.v-data-table table td {
  padding: 5px !important;
}
.Completed {
  background: rgba(0, 255, 0, 0.15);
  &:hover {
    background: rgba(0, 255, 0, 0.1) !important;
  }
}
.Refunded,
.Disputed {
  background: rgba(255, 0, 0, 0.15);
  &:hover {
    background: rgba(255, 0, 0, 0.1) !important;
  }
}
.Suspicious,
.blacklist {
  background: rgba(255, 0, 170, 0.15);
  &:hover {
    background: rgba(255, 0, 170, 0.1) !important;
  }
}
.Claim {
  background: rgba(255, 123, 0, 0.15);
  &:hover {
    background: rgba(255, 123, 0, 0.1) !important;
  }
}
.small-select {
  width: 100px;
  font-size: 0.9em;
}
.copy-to-clipboard {
  display: inline;
  cursor: pointer;
  border-bottom: 1px dashed dodgerblue;
  font-size: 0.9em;
}
.comment-row {
  cursor: pointer;
  border-bottom: 1px dashed dodgerblue;
  font-size: 0.9em;
}
.text-xs {
  font-size: 0.9em;
}
.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
