@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';




































































































.disputes {
  padding: 16px;
}
